import { RequestConfig } from '@lucidtech/las-sdk-core';

export enum QueueStatus {
  LOADING = 'LOADING',
  LOADING_FIRST_TRANSITIONEXECUTION = 'LOADING_FIRST_TRANSITIONEXECUTION',
  EMPTY = 'EMPTY',
  READY = 'READY',
}

export type PrivateProfile = {
  createdTime: string | null;
  email: string | null;
  familyName: string | null;
  givenName: string | null;
  locale: string | null;
  metadata: Record<string, unknown> | null;
  numberOfOrganizationsAllowed: number;
  numberOfOrganizationsCreated: number;
  picture: string | null;
  profileId: string;
  updatedTime: string | null;
};

export type UpdateProfileOptions = RequestConfig & {
  givenName?: string | null;
  familyName?: string | null;
  locale?: string | null;
  metadata?: Record<string, unknown> | null;
  picture?: string | null;
};

export type PublicProfile = Pick<PrivateProfile, 'givenName' | 'familyName' | 'picture' | 'profileId' | 'email'>;

export type FormConfigLabelOption =
  | {
      value: string;
      display: string;
    }
  | string;

export type Validator = 'norwegian_kid' | 'norwegian_bank_account' | 'norwegian_organization_number';

export type FormConfigV1 = {
  version: 'v1';
  config: {
    fields: Record<string, FormConfigLabel>;
    allowOverrideSubmit?: boolean;
  };
};

export type FormConfigLabel = {
  validators?: Array<Validator>;
  required?: boolean;
  display?: string;
  confidenceLevels?:
    | {
        automated?: number;
        high?: number;
        medium?: number;
        low?: number;
      }
    | number;
  autoCompleteOptions?: Array<FormConfigLabelOption | string> | string;
  type: 'string' | 'date' | 'enum' | 'numeric' | 'lines';
};

export type FormConfig = FormConfigV1;
