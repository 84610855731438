import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { QueueStatus } from '../../types';
import { Transition, TransitionExecution } from '@lucidtech/las-sdk-core';
// import { defaultClient } from '../../__mocks__/@lucidtech/las-sdk-core'; // eslint-disable-line
import { defaultClient } from '../../auth';

interface TransitionState {
  transition: Transition;
  // for now we're only queuing up one background task, so this could just be `TransitionExecution`,
  // but this makes it easier to extend in the future if we decide we want to support multiple background tasks being loaded
  queue: Array<TransitionExecution>;
  queueStatus: QueueStatus;
  transitionExecution: TransitionExecution | null;
  numProcessed: number;
  // we use this to signal that when a new fetched execution is fulfilled, we want it set as the new transitionExecution
  // instead of added to the queue
  wantsTransitionExecution: boolean;
}

/** Fetch transitions ("queues" under Approve sidebar navigation) */
export const fetchTransitions = createAsyncThunk('approve/transition/fetch', async () => {
  const client = defaultClient();
  // this could be paginated, but for now lets just assume we won't ever be getting 1000+ transitions for simplicity's sake...
  const { transitions } = await client.listTransitions();
  return transitions;
});

/**
 * Send heartbeats to current transition execution, and any that exist in the queue.
 */
export const sendHeartbeat = createAsyncThunk(
  'approve/transitionExecution/heartbeat',
  async ({ transitionId }: { transitionId: string }, thunkAPI) => {
    const { getState } = thunkAPI;
    const state = getState() as any;
    const transitionState = state.approve.transitions[transitionId];
    if (!transitionState) return;

    const currentTransitionExecution: TransitionExecution | null =
      state.approve.transitions[transitionId].transitionExecution;
    const queue: Array<TransitionExecution> = state.approve.transitions[transitionId].queue;

    const transitionExecutionsToSendHeartbeatsTo = [currentTransitionExecution, ...queue];
    const client = defaultClient();

    const heartBeatPromisePromises = transitionExecutionsToSendHeartbeatsTo
      .filter((transitionExecution) => transitionExecution !== null)
      .map((transitionExecution) => client.sendHeartbeat(transitionId, transitionExecution!.executionId));
    const res = await Promise.allSettled(heartBeatPromisePromises);
    return res;
  }
);

/**
 *
 */
export const requestNewTransitionExecution = createAsyncThunk(
  'approve/transitionExecution/requestNew',
  async ({ transitionId }: { transitionId: string }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    // Figure out whether we need to fetch just one or two transition executions
    const state = getState() as any;
    const transitionState = state.approve.transitions[transitionId];
    const hasQueueItem = transitionState.queue.length > 0;

    // We're always fetching at least one (?)
    dispatch(fetchTransitionExecution({ transitionId }));

    // If we don't have another execution in the queue, let's fetch one extra
    if (!hasQueueItem) {
      dispatch(fetchTransitionExecution({ transitionId }));
    }

    return;
  }
);

export const fetchTransitionExecution = createAsyncThunk(
  'approve/transitionExecution/fetch',
  async ({ transitionId }: { transitionId: string }, thunkAPI) => {
    const client = defaultClient();
    const transitionExecution = await client.executeTransition(transitionId);

    return { transitionExecution, transitionId };
  }
);

export const skipTransitionExecution = createAsyncThunk(
  'approve/transitionExecution/skip',
  async ({ transitionId, executionId, reason }: { transitionId: string; executionId: any; reason: string }) => {
    const errorPayload = { message: reason };
    await defaultClient().updateTransitionExecution(transitionId, executionId, {
      status: 'retry',
      error: errorPayload,
    });
  }
);

export const approveTransitionExecution = createAsyncThunk(
  'approve/transitionExecution/approve',
  async ({
    transitionId,
    executionId,
    transitionExecutionResult,
    startTime,
  }: {
    transitionId: string;
    executionId: string;
    transitionExecutionResult: Record<string, unknown>;
    startTime?: string;
  }) => {
    const payload = {
      status: 'succeeded' as const,
      output: transitionExecutionResult,
    };
    if (startTime) {
      payload['startTime'] = startTime;
    }
    await defaultClient().updateTransitionExecution(transitionId, executionId, payload);
  }
);

export const rejectTransitionExecution = createAsyncThunk(
  'approve/transitionExecution/reject',
  async ({
    transitionId,
    executionId,
    transitionExecutionError,
  }: {
    transitionId: string;
    executionId: string;
    transitionExecutionError: string;
  }) => {
    await defaultClient().updateTransitionExecution(transitionId, executionId, {
      status: 'rejected',
      error: {
        message: transitionExecutionError,
      },
    });
  }
);

// "cleans up" any state that might exist for a transition, and fetches a new transition execution (or two)
export const startTransition = createAsyncThunk('approve/transition/start', async (transitionId: string, thunkAPI) => {
  const { dispatch } = thunkAPI;
  // requestNewTransitionExecution takes care of figuring out how many requests we should do
  dispatch(requestNewTransitionExecution({ transitionId }));
});

const increaseProcessedCounter = (state, action): void => {
  const { transitionId } = action.meta.arg;

  if (transitionId) {
    state.transitions[transitionId].numProcessed += 1;
  }
};

type ApproveState = {
  transitions: Record<string, TransitionState>;
  loading: boolean;
};

const slice = createSlice({
  name: 'approve',
  initialState: { loading: true, transitions: {} } as ApproveState,
  reducers: {
    setQueueStatus: (state, action: PayloadAction<{ transitionId: string; status: QueueStatus }>): void => {
      const { transitionId, status } = action.payload;
      if (state.transitions[transitionId]) {
        state.transitions[transitionId].queueStatus = status;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchTransitions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransitions.fulfilled, (state, action) => {
        const transitions = action.payload;

        for (const transition of transitions) {
          state.transitions[transition.transitionId] = {
            transition,
            numProcessed: 0,
            queue: [],
            queueStatus: QueueStatus.LOADING_FIRST_TRANSITIONEXECUTION,
            transitionExecution: null,
            wantsTransitionExecution: true,
          };
        }

        state.loading = false;
      })
      // I think we only really care about the dispatch of this action --
      // so it's enough to do the state cleanup in the pending state of this
      // and ignore the fulfillment?
      .addCase(startTransition.pending, (state, action) => {
        const transitionId = action.meta.arg;
        if (!transitionId) {
          console.error('Tried to start transition with no transitionId');
          return;
        }

        const prevTransition = state.transitions[transitionId].transition;
        state.transitions[transitionId] = {
          transition: prevTransition, // we want to preserve the actual Transition itself
          numProcessed: 0,
          queue: [],
          queueStatus: QueueStatus.LOADING_FIRST_TRANSITIONEXECUTION,
          transitionExecution: null,
          wantsTransitionExecution: true,
        };
      })
      .addCase(requestNewTransitionExecution.pending, (state, action) => {
        const { transitionId } = action.meta.arg;
        state.transitions[transitionId].wantsTransitionExecution = true;
      })
      .addCase(fetchTransitionExecution.pending, (state, action) => {
        const { transitionId } = action.meta.arg;

        // if we already have another transition execution in the background queue
        // we set that as the new transition execution while we load the next one
        // (if a new one is requested signaled by wantsTransitionExecution)
        const wantsTransitionExecution = state.transitions[transitionId].wantsTransitionExecution;
        const hasItemInQueue = state.transitions[transitionId].queue.length > 0;
        if (wantsTransitionExecution && hasItemInQueue) {
          const nextTransitionExecution = state.transitions[transitionId].queue.shift();
          // we just checked that the length is > 0, so we know shift() will not return undefined
          state.transitions[transitionId].transitionExecution = nextTransitionExecution!;
          state.transitions[transitionId].queueStatus = QueueStatus.READY;
          state.transitions[transitionId].wantsTransitionExecution = false;
        }

        // we have requested a new transition, but nothing currently in queue, set us in a loading state
        const isFirstRequest =
          state.transitions[transitionId].queueStatus === QueueStatus.LOADING_FIRST_TRANSITIONEXECUTION;
        if (wantsTransitionExecution && !hasItemInQueue && !isFirstRequest) {
          state.transitions[transitionId].queueStatus = QueueStatus.LOADING;
        }
      })
      .addCase(fetchTransitionExecution.rejected, (state, action) => {
        const { transitionId } = action.meta.arg;
        const wantsTransitionExecution = state.transitions[transitionId].wantsTransitionExecution;
        // if this was the first execution we tried to load and it fails, set queue as empty
        // also set as empty if a new task was requested
        if (
          state.transitions[transitionId].queueStatus === QueueStatus.LOADING_FIRST_TRANSITIONEXECUTION ||
          wantsTransitionExecution
        ) {
          state.transitions[transitionId].queueStatus = QueueStatus.EMPTY;
        }
      })
      .addCase(fetchTransitionExecution.fulfilled, (state, action) => {
        const { transitionExecution, transitionId } = action.payload;
        const wantsTransitionExecution = state.transitions[transitionId].wantsTransitionExecution;

        if (wantsTransitionExecution) {
          state.transitions[transitionId].transitionExecution = transitionExecution;
          state.transitions[transitionId].queueStatus = QueueStatus.READY;
          state.transitions[transitionId].wantsTransitionExecution = false;
        } else {
          state.transitions[transitionId].queue.push(transitionExecution);
        }
      })
      .addCase(approveTransitionExecution.fulfilled, increaseProcessedCounter)
      .addCase(rejectTransitionExecution.fulfilled, increaseProcessedCounter),
});

export const actions = slice.actions;
export const reducer = slice.reducer;
