import React from 'react';
import * as las from '@lucidtech/las-sdk-browser';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { createCredentials } from '../auth';
import { actions as session } from '../store/slices/session';

const AuthSuccess = (): JSX.Element => {
  const dispatch = useDispatch();

  const search = new URLSearchParams(window.location.search);
  const code = search.get('code') || undefined;

  // Logout scenario
  if (!code) {
    window.sessionStorage.removeItem('AuthToken');
    window.localStorage.removeItem('AuthToken');
    return <Redirect to={'/login'} />;
  }

  const pkce = las.PKCEDerived.createFromCode(code) || undefined;
  const clientId = window.localStorage.getItem('clientId');
  const credentials = createCredentials(clientId, pkce);

  credentials
    .getAccessToken()
    .then(() => {
      dispatch(session.setIsLoggedIn(true));
    })
    .catch((error: any) => console.error('Error receiving access token', error));

  return <Redirect to="/" />;
};

export default AuthSuccess;
