import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hasCredentials } from '../../auth';

interface SessionState {
  isLoggedIn: boolean;
}

const initialState: SessionState = {
  isLoggedIn: hasCredentials(),
};

const slice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>): void => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
