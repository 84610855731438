import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AuthSuccess from './AuthSuccess';
import Sidebar from './Sidebar';

import { RootState } from '../store';
import { fetchWorkflows } from '../store/slices/workflows';
import { fetchTransitions } from '../store/slices/approve';
import { fetchMe } from '../store/slices/users';

import styles from './App.module.css';
import { WorkflowLoadingState } from './WorkflowEmptyState/WorkflowLoadingState';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
});

const ApproveView = lazy(() => import('./ApproveView/index'));
const IncomingView = lazy(() => import('./IncomingView/index'));
const DashboardView = lazy(() => import('./DashboardView/index'));
const ProcessedView = lazy(() => import('./ProcessedView/index'));
const LoginView = lazy(() => import('./LoginView/index'));
const UsersAdminView = lazy(() => import('./UsersAdminView/index'));

const App = (): JSX.Element => {
  const loggedIn = useSelector((state: RootState) => state.session.isLoggedIn);
  const dispatch = useDispatch();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleCollapse = (): void => setIsSidebarCollapsed((prev) => !prev);
  // init, fetch workflows and transitions for every workflow
  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    dispatch(fetchWorkflows());
    dispatch(fetchTransitions());
    dispatch(fetchMe());
  }, [dispatch, loggedIn]);

  useEffect(() => {
    const listener = (event: KeyboardEvent): void => {
      if (event.repeat) return;
      if (event.ctrlKey && event.shiftKey && event.key === 'Z') {
        toggleCollapse();
      }
    };
    document.addEventListener('keydown', listener);
    return (): void => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path="/logout"
            render={(): JSX.Element => {
              return <Redirect to="/login" />;
            }}
          />
          <Route exact path="/login">
            <LoginView />
          </Route>
          <Route exact path="/authCallback">
            <AuthSuccess />
          </Route>
          <Route path="/">
            {!loggedIn ? (
              <LoginView />
            ) : (
              <div className={styles['wrapper']}>
                <Sidebar collapsed={isSidebarCollapsed} toggleCollapse={toggleCollapse} />
                <div className={`${styles['content']} ${isSidebarCollapsed ? '' : styles['isOpen']}`}>
                  <Row className="justify-content-center">
                    <Col xs={12}>
                      <Suspense fallback={null}>
                        <Route exact path={`/dashboard`}>
                          <DashboardView />
                        </Route>
                        <Route path={`/approve/:transitionId`}>
                          <ApproveView />
                        </Route>
                        <Route path={`/admin`}>
                          <UsersAdminView />
                        </Route>
                        <Route exact path="/">
                          <Redirect to="/incoming" />
                        </Route>
                        <Route exact path="/incoming">
                          <WorkflowLoadingState redirectGroup="incoming" />
                        </Route>
                        <Route exact path="/incoming/:workflowId">
                          <IncomingView />
                        </Route>
                        <Route exact path="/processed">
                          <WorkflowLoadingState redirectGroup="processed" />
                        </Route>
                        <Route path="/processed/:workflowId">
                          <ProcessedView />
                        </Route>
                      </Suspense>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Route>
        </Switch>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
