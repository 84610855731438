import React from 'react';
import preval from 'preval.macro';

const buildVersion = preval`
const execSync = require('child_process').execSync;
const output = execSync('git rev-parse --short HEAD', { encoding: 'ascii' });
module.exports = output;
`;

const Version = (): JSX.Element => (
  <div className="text-muted" style={{ fontSize: '0.7rem' }}>
    Build version {buildVersion}
  </div>
);

export default Version;
