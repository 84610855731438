import React, { ReactNode } from 'react';
import { styled } from '../../stitches.config';
import { IconCard } from '../IconCard/IconCard';
import { ReactComponent as FileSearchIcon } from '../../assets/icons/file-search.svg';
import { ReactComponent as FileXIcon } from '../../assets/icons/file-x.svg';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Progress } from 'reactstrap';

import { RootState } from '../../store';
import { fetchWorkflows } from '../../store/slices/workflows';
import { Button } from '../Button';
import { FiRefreshCw } from 'react-icons/fi';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  maxWidth: '350px',
  '& h2, & p': {
    margin: 0,
  },
  '& p': {
    color: '$gray500',
  },
  '& button': {
    gap: '0.5em',
    marginRight: 'auto',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  my: '40px',
  mx: 'auto',
});

export type WorkflowLoadingProps = {
  children?: ReactNode;
};
function WorkflowLoading({ children }: WorkflowLoadingProps): JSX.Element {
  return (
    <Container>
      <IconCard>
        <FileSearchIcon />
      </IconCard>
      <h2>Loading workflows...</h2>
      {children}
    </Container>
  );
}

export type NoWorkflowsFoundProps = {
  onRetry?: () => void;
};
function NoWorkflowsFound({ onRetry }: NoWorkflowsFoundProps): JSX.Element {
  return (
    <Container>
      <IconCard>
        <FileXIcon />
      </IconCard>
      <h2>No workflows found</h2>
      <Button onClick={onRetry}>
        <FiRefreshCw />
        Look again
      </Button>
    </Container>
  );
}

const TEN_SECONDS = 10 * 1000;

export type WorkflowLoadingStateProps = {
  redirectGroup: 'incoming' | 'processed';
};
export const WorkflowLoadingState = ({ redirectGroup = 'incoming' }: WorkflowLoadingStateProps): JSX.Element => {
  const workflows = useSelector((state: RootState) => state.workflows.workflows);
  const isLoading = useSelector((state: RootState) => state.workflows.isLoading);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
    let interval;

    if (isLoading) {
      interval = setInterval(() => {
        if (progress > TEN_SECONDS) {
          clearInterval(interval);
          return;
        }

        setProgress((p) => p + 100);
      }, 100);
    }

    return (): void => {
      clearInterval(interval);
    };
  }, [isLoading, TEN_SECONDS]); // eslint-disable-line

  const onRefresh = (): void => {
    dispatch(fetchWorkflows());
  };

  if (isLoading) {
    return (
      <WorkflowLoading>
        <Progress value={progress} max={TEN_SECONDS} />
      </WorkflowLoading>
    );
  }

  if (Object.keys(workflows).length === 0) {
    return <NoWorkflowsFound onRetry={onRefresh} />;
  }

  return <Redirect to={`/${redirectGroup}/${Object.keys(workflows)[0]}`} />;
};
