import { User } from '@lucidtech/las-sdk-core';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { defaultClient } from '../../auth';
import { PrivateProfile, PublicProfile, UpdateProfileOptions } from '../../types';

export type ActionState = {
  isLoading: boolean;
  error: null | string;
};

type UsersState = {
  hasBeenFetched: boolean;
  isLoading: boolean;
  users: Array<User>;
  profiles: Record<string, { isLoading: boolean; profile: PublicProfile | null }>;
  me: {
    info: User | null;
    profile: PrivateProfile | null;
    isUpdating: boolean;
  } & ActionState;
  nextToken: string | undefined | null;
  error: string | null;
  addUser: ActionState;
  deleteUser: { [key: string]: ActionState };
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (_payload, { signal, dispatch, getState }) => {
  let done = false;
  let requests = 0;

  const state = getState() as any;
  let nextToken = state.users.nextToken;

  if (nextToken === null) {
    done = true;
  }

  const client = defaultClient();

  while (!done) {
    if (signal.aborted) {
      throw new Error('Canceling');
    }

    // no more results
    if (requests > 0 && !nextToken) {
      done = true;
      break;
    }

    const res = await client.listUsers({ nextToken });
    for (const user of res.users) {
      // @ts-expect-error
      dispatch(fetchProfile({ profileId: user.profileId }));
    }

    nextToken = res.nextToken;

    dispatch(slice.actions.addUsers(res.users));

    requests++;
  }

  return { nextToken };
});

export const fetchProfile = createAsyncThunk('profiles/fetchProfile', async (payload: { profileId: string }) => {
  const client = defaultClient();
  const profile = await client.makeGetRequest<PublicProfile>(`/profiles/${payload.profileId}`);

  return profile;
});

export const fetchMe = createAsyncThunk('users/fetchMe', async (_payload, { signal, dispatch, getState }) => {
  const client = defaultClient();
  const user = await client.getUser('me');
  const profile = await client.makeGetRequest<PrivateProfile>(`/profiles/me`);

  return { user, profile };
});

export const updateMe = createAsyncThunk(
  'users/updateMe',
  async (payload: UpdateProfileOptions, { signal, dispatch, getState }) => {
    const client = defaultClient();
    const me = await client.makePatchRequest<PrivateProfile>('/profiles/me', payload);

    return me;
  }
);

export const addUser = createAsyncThunk(
  'users/addUser',
  async (payload: { email: string; name?: string }, { signal, dispatch, getState }) => {
    const { email, name } = payload;
    const client = defaultClient();
    const user = await client.createUser(email, { name });

    return user;
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (payload: string, { signal, dispatch, getState }) => {
    const client = defaultClient();
    await client.deleteUser(payload);

    return payload;
  }
);

const slice = createSlice({
  name: 'users',
  initialState: {
    hasBeenFetched: false,
    isLoading: false,
    users: [],
    profiles: {},
    me: { info: null, isLoading: true, error: null, isUpdating: false, profile: null },
    error: null,
    nextToken: undefined,
    addUser: { error: null, isLoading: false },
    deleteUser: {},
  } as UsersState,
  reducers: {
    addUsers: (state, action: PayloadAction<any[]>): void => {
      const { payload } = action;
      state.users = state.users.concat(payload);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchProfile.pending, (state, action) => {
        if (!state.profiles[action.meta.arg.profileId]) {
          state.profiles[action.meta.arg.profileId] = { isLoading: true, profile: null };
        }
        state.profiles[action.meta.arg.profileId].isLoading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profiles[action.meta.arg.profileId] = { isLoading: false, profile: action.payload };
      })
      .addCase(fetchUsers.pending, (state, _action) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const { nextToken } = action.payload;
        state.isLoading = false;
        state.nextToken = nextToken;
        state.hasBeenFetched = true;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        console.error(action.error);
        state.error = action.error.message || 'Unknown error loading users';
        state.isLoading = false;
      })
      .addCase(fetchMe.pending, (state, _action) => {
        state.me.isLoading = true;
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        state.me.isLoading = false;
        // @ts-ignore
        state.me.info = action.payload.user;
        state.me.profile = action.payload.profile;
      })
      .addCase(fetchMe.rejected, (state, action) => {
        console.error(action.error);
        state.me.error = action.error.message || 'Unknown error loading users';
        state.me.isLoading = false;
      })
      .addCase(updateMe.pending, (state, _action) => {
        state.me.isUpdating = true;
      })
      .addCase(updateMe.fulfilled, (state, action) => {
        state.me.isUpdating = false;
        // @ts-ignore
        state.me.profile = action.payload;
        state.profiles[action.payload.profileId] = { profile: action.payload, isLoading: false };
      })
      .addCase(updateMe.rejected, (state, action) => {
        console.error(action.error);
        state.me.error = action.error.message || 'Unknown error loading users';
        state.me.isUpdating = false;
      })
      .addCase(addUser.pending, (state, _action) => {
        state.addUser = { isLoading: true, error: null };
      })
      .addCase(addUser.fulfilled, (state, action) => {
        // @ts-ignore
        state.users = state.users.concat(action.payload);
        state.addUser = { isLoading: false, error: null };
      })
      .addCase(addUser.rejected, (state, action) => {
        console.error(action.error);
        state.addUser = { isLoading: false, error: action.error.message || 'Unknown error adding user' };
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.deleteUser[action.meta.arg] = { isLoading: true, error: null };
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const index = state.users.findIndex((user) => user.userId === action.payload);
        if (index >= 0) {
          state.users.splice(index, 1);
        }
        state.deleteUser[action.meta.arg] = { isLoading: false, error: null };
      })
      .addCase(deleteUser.rejected, (state, action) => {
        console.error(action.error);
        state.deleteUser[action.meta.arg] = {
          isLoading: false,
          error: action.error.message || 'Unknown error adding user',
        };
      }),
});

export const actions = slice.actions;
export const reducer = slice.reducer;
