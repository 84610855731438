import React, { FunctionComponent, PropsWithChildren } from 'react';

interface EntryProps {
  id: string;
  question: string;
}

const Entry: FunctionComponent<EntryProps> = ({ id, question, children }: PropsWithChildren<EntryProps>) => (
  <>
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button
          className="btn btn-link"
          type="button"
          data-toggle="collapse"
          data-target={`#${id}`}
          aria-expanded="false"
          aria-controls="collapseOne"
        >
          {question}
        </button>
      </h2>
    </div>

    <div id={id} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">{children}</div>
    </div>
  </>
);

const FAQ = (): JSX.Element => (
  <div className="accordion" id="accordionExample">
    <div className="card">
      <Entry id="q1" question="What is a timeout?">
        When you start approving in a work queue, you are assigned a task from that work queue that must be completed
        within 15 minutes. If you do not complete the task within that time frame, the task is automatically
        re-submitted to the work queue. The timeout ensures that all tasks are completed in a timely manner.
      </Entry>

      <Entry id="q2" question="What is a work queue?">
        A work queue is simply a list of tasks that can be assigned to an operator. When you select a work queue, you
        are assigned tasks from that work queue. Different work queues may be used to seggregate between different types
        of tasks. For example, a priority queue may be used to ensure that urgent tasks are handled immediately while
        non-urgent tasks can be handled in an ordinary work queue.
      </Entry>

      <Entry id="q3" question="What happens when I reject a task?">
        When you reject a task, the task is removed from the work queue and discarded. This prevents the task from
        propagating in the work flow, and effectively "deletes" the task from Supervise.
      </Entry>

      <Entry id="q4" question="What happens when I skip a task?">
        When you skip a task, the task is simply but back into the work queue. You may for example skip a task if you
        are unsure about how to process the task correctly. In this case, the skipped task will be assigned to another
        operator.
      </Entry>

      <Entry id="q5" question="How do I contact you?">
        Any questions, comments or suggestions may be direct to Lucidtech at: support@lucidtech.ai
      </Entry>
    </div>
  </div>
);

export default FAQ;
