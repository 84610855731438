import {
  LasDocument,
  LasDocumentWithoutContent,
  PredictionResponse,
  TransitionExecution,
  WorkflowExecution,
} from '@lucidtech/las-sdk-core';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { isBefore, subMonths } from 'date-fns';
import { b64DecodeUnicode } from '../../utils';
// import { defaultClient } from '../../__mocks__/@lucidtech/las-sdk-core'; // eslint-disable-line
import { defaultClient } from '../../auth';

interface TransitionState {
  nextToken: string | undefined | null;
  executions: Array<TransitionExecution>;
  isLoading: boolean;
  error: string | null;
}

interface WorkflowState {
  nextToken: string | undefined | null;
  executions: Array<WorkflowExecution>;
  isLoading: boolean;
  error: string | null;
}

interface PredictionState {
  nextToken: string | undefined | null;
  predictions: Array<any>;
  isLoading: boolean;
  error: string | null;
}

interface DocumentState {
  nextToken: string | undefined | null;
  documents: Array<LasDocument>;
  isLoading: boolean;
  error: string | null;
}

const emptyPredictionState: PredictionState = {
  nextToken: undefined,
  predictions: [],
  isLoading: false,
  error: null,
};

const emptyDocumentState: DocumentState = {
  nextToken: undefined,
  documents: [],
  isLoading: false,
  error: null,
};

export type FieldConfig = {
  /** field name */
  [key: string]: {
    type: string;
    display: string;
    confidenceLevels: { automated: number; high: number; medium: number; low: number };
    groundTruthName?: string;
  };
};

type DashboardState = {
  isLoading: boolean;
  transitionExecutions: Record<string, TransitionState>;
  predictions: PredictionState;
  documents: DocumentState;
  fieldConfig: {
    isLoading: boolean;
    error: string | null;
    config: FieldConfig;
  };
  workflows: Record<string, WorkflowState>;
};

const MAX_BATCH_LOAD_PROCESSES = 500;
export const fetchProcessedWorkflowExecutionsForDashboard = createAsyncThunk(
  'workflows/fetchProcessedWorkflowExecutionsForDashboard',
  async ({ workflowId }: { workflowId: string }, { signal, dispatch, getState }) => {
    let done = false;
    let requests = 0;
    let executions = 0;
    const state = getState() as any;
    let nextToken = state.workflows[workflowId]?.nextToken;

    while (!done) {
      if (signal.aborted) {
        throw new Error('Canceling');
      }

      // no more results
      if (requests > 0 && !nextToken) {
        done = true;
        break;
      }

      if (executions >= MAX_BATCH_LOAD_PROCESSES) {
        done = true;
        break;
      }

      const client = defaultClient();
      const res = await client.listWorkflowExecutions(workflowId, {
        status: 'succeeded',
        maxResults: 100,
        sortBy: 'endTime',
        order: 'descending',
        nextToken,
      });

      nextToken = res.nextToken;

      dispatch(slice.actions.addWorkflowExecutions({ workflowId, executions: res.executions }));

      executions += res.executions.length;
      requests++;
    }

    return { nextToken, workflowId };
  }
);

export const fetchDocuments = createAsyncThunk(
  'dashboard/fetchDocuments',
  async (_payload, { signal, dispatch, getState }) => {
    let done = false;
    let requests = 0;

    const state = getState() as any;
    let nextToken = state.dashboard.predictions.nextToken;

    if (nextToken === null) {
      done = true;
    }

    const client = defaultClient();

    const today = new Date();
    const cutoffDate = subMonths(today, 1);

    while (!done) {
      if (signal.aborted) {
        throw new Error('Canceling');
      }

      // no more results
      if (requests > 0 && !nextToken) {
        done = true;
        break;
      }

      const res = await client.listDocuments({ nextToken, sortBy: 'createdTime', order: 'descending' });

      // check if any prediction is before our cutoff date, then stop fetching
      // we also only add the ones within the time span
      let hasDocumentBeforeCutoff = false;
      const documentsAfterCutoffDate: Array<LasDocumentWithoutContent> = [];
      res.documents?.forEach((doc) => {
        if (doc.createdTime) {
          const predictionStartDate = new Date(doc.createdTime);
          if (isBefore(predictionStartDate, cutoffDate)) {
            hasDocumentBeforeCutoff = true;
            return;
          }
          documentsAfterCutoffDate.push(doc);
        }
      });

      nextToken = hasDocumentBeforeCutoff ? null : res.nextToken;

      dispatch(slice.actions.addDocuments(documentsAfterCutoffDate));

      // executions += res.executions.length;
      requests++;
    }

    return { nextToken };
  }
);

export const fetchFieldConfig = createAsyncThunk(
  'dashboard/fetchFieldConfig',
  async ({ assetId }: { assetId: string }, { signal, dispatch, getState }) => {
    const client = defaultClient();
    const asset = await client.getAsset(assetId);
    const decoded = b64DecodeUnicode(asset.content!);
    const fieldConfig: FieldConfig = JSON.parse(decoded);

    return fieldConfig;
  }
);

export const fetchPredictions = createAsyncThunk(
  'dashboard/fetchPredictions',
  async (_payload, { signal, dispatch, getState }) => {
    let done = false;
    let requests = 0;

    const state = getState() as any;
    let nextToken = state.dashboard.predictions.nextToken;

    if (nextToken === null) {
      done = true;
    }

    const today = new Date();
    const cutoffDate = subMonths(today, 1);

    const client = defaultClient();

    while (!done) {
      if (signal.aborted) {
        throw new Error('Canceling');
      }

      // no more results
      if (requests > 0 && !nextToken) {
        done = true;
        break;
      }

      const res = await client.listPredictions({ nextToken, sortBy: 'createdTime', order: 'descending' });

      // check if any prediction is before our cutoff date, then stop fetching
      // we also only add the ones within the time span
      let hasPredictionBeforeCutoff = false;
      const predictionsAfterCutoffDate: Array<PredictionResponse> = [];
      for (const prediction of res.predictions || []) {
        if (prediction.createdTime) {
          const predictionStartDate = new Date(prediction.createdTime);
          if (isBefore(predictionStartDate, cutoffDate)) {
            console.log('found before time');
            hasPredictionBeforeCutoff = true;
            break;
          }
          predictionsAfterCutoffDate.push(prediction);
        }
      }

      nextToken = hasPredictionBeforeCutoff ? null : res.nextToken;

      dispatch(slice.actions.addPredictions(predictionsAfterCutoffDate));

      // executions += res.executions.length;
      requests++;
    }

    return { nextToken };
  }
);

export const fetchTransitionExecutions = createAsyncThunk(
  'dashboard/fetchTransitionExecutions',
  async ({ transitionId }: { transitionId: string }, { signal, dispatch, getState }) => {
    let done = false;
    let requests = 0;
    let nextToken;
    const state = getState() as any;
    if (state.dashboard.transitionExecutions[transitionId]) {
      nextToken = state.dashboard.transitionExecutions[transitionId].nextToken;
    }

    if (nextToken === null) {
      done = true;
    }

    const today = new Date();
    const cutoffDate = subMonths(today, 1);

    const client = defaultClient();
    while (!done) {
      if (signal.aborted) {
        throw new Error('Canceling');
      }

      // no more results
      if (requests > 0 && !nextToken) {
        done = true;
        break;
      }

      const res = await client.listTransitionExecutions(transitionId, {
        sortBy: 'endTime',
        order: 'descending',
        nextToken,
      });

      // check if any execution is before our cutoff date, then stop fetching
      // we also only add the ones within the time span
      let hasExecutionBeforeCutoff = false;
      const executionsAfterCutoffDate: Array<TransitionExecution> = [];
      res.executions?.forEach((execution) => {
        if (execution.endTime) {
          const executionEndDate = new Date(execution.endTime);
          if (isBefore(executionEndDate, cutoffDate)) {
            hasExecutionBeforeCutoff = true;
            return;
          }
          executionsAfterCutoffDate.push(execution);
        }
      });

      nextToken = hasExecutionBeforeCutoff ? null : res.nextToken;

      dispatch(
        slice.actions.addTransitionExecutions({
          transitionId,
          executions: executionsAfterCutoffDate,
        })
      );

      requests++;
    }

    return { nextToken, transitionId };
  }
);

const slice = createSlice({
  name: 'dashboard',
  initialState: {
    isLoading: true,
    transitionExecutions: {},
    predictions: emptyPredictionState,
    documents: emptyDocumentState,
    fieldConfig: {
      isLoading: true,
      error: null,
      config: {},
    },
    workflows: {},
  } as DashboardState,
  reducers: {
    addTransitionExecutions: (
      state,
      action: PayloadAction<{
        transitionId: string;
        executions: Array<TransitionExecution>;
      }>
    ): void => {
      const { transitionId, executions } = action.payload;
      // sanity check that this exist first
      if (!state.transitionExecutions[transitionId]) {
        return;
      }

      state.transitionExecutions[transitionId].executions =
        state.transitionExecutions[transitionId].executions.concat(executions);
    },
    addPredictions: (state, action: PayloadAction<any[]>): void => {
      const { payload } = action;
      state.predictions.predictions = state.predictions.predictions.concat(payload);
    },
    addDocuments: (state, action: PayloadAction<any[]>): void => {
      const { payload } = action;
      state.documents.documents = state.documents.documents.concat(payload);
    },
    addWorkflowExecutions: (
      state,
      action: PayloadAction<{
        workflowId: string;
        executions: Array<WorkflowExecution>;
      }>
    ): void => {
      const { workflowId, executions } = action.payload;
      // sanity check that this workflow exist first
      if (!state.workflows[workflowId]) {
        return;
      }
      // filter out new workflowexecutions that already exist, so we don't accidentally add duplicates
      const newWorkflowExecutionsToAdd = executions.filter(
        (newWorkflowExecution) =>
          !state.workflows[workflowId].executions.some(
            (workflowExecution: WorkflowExecution) => workflowExecution.executionId === newWorkflowExecution.executionId
          )
      );
      state.workflows[workflowId].executions =
        state.workflows[workflowId].executions.concat(newWorkflowExecutionsToAdd);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchProcessedWorkflowExecutionsForDashboard.pending, (state, action) => {
        const { workflowId } = action.meta.arg;
        if (!state.workflows[workflowId]) {
          state.workflows[workflowId] = { executions: [], isLoading: true, error: null, nextToken: null };
        }
        state.workflows[workflowId].isLoading = true;
      })
      .addCase(fetchProcessedWorkflowExecutionsForDashboard.fulfilled, (state, action) => {
        const { workflowId, nextToken } = action.payload;
        if (!state.workflows[workflowId]) {
          state.workflows[workflowId] = { executions: [], isLoading: false, error: null, nextToken };
        }
        state.workflows[workflowId].nextToken = nextToken;
        state.workflows[workflowId].isLoading = false;
      })
      .addCase(fetchProcessedWorkflowExecutionsForDashboard.rejected, (state, action) => {
        const { workflowId } = action.meta.arg;
        console.error(action.error);
        if (!state.workflows[workflowId]) {
          state.workflows[workflowId] = {
            executions: [],
            isLoading: false,
            error: 'Error loading workflow executions',
            nextToken: null,
          };
        }
        state.workflows[workflowId].error = 'Error loading workflow executions';
        state.workflows[workflowId].isLoading = false;
      })
      .addCase(fetchTransitionExecutions.pending, (state, action) => {
        const { transitionId } = action.meta.arg;
        if (!state.transitionExecutions[transitionId]) {
          state.transitionExecutions[transitionId] = {
            isLoading: true,
            nextToken: undefined,
            executions: [],
            error: null,
          };
        }
        state.transitionExecutions[transitionId].isLoading = true;
      })
      .addCase(fetchTransitionExecutions.fulfilled, (state, action) => {
        const { transitionId, nextToken } = action.payload;

        state.transitionExecutions[transitionId].nextToken = nextToken;
        state.transitionExecutions[transitionId].isLoading = false;
      })
      .addCase(fetchTransitionExecutions.rejected, (state, action) => {
        const { transitionId } = action.meta.arg;
        console.error(action.error);
        state.transitionExecutions[transitionId].error = 'Error loading transition executions';
        state.transitionExecutions[transitionId].isLoading = false;
      })
      .addCase(fetchPredictions.pending, (state, _action) => {
        state.predictions.isLoading = true;
      })
      .addCase(fetchPredictions.fulfilled, (state, action) => {
        const { nextToken } = action.payload;
        state.predictions.isLoading = false;
        state.predictions.nextToken = nextToken;
      })
      .addCase(fetchPredictions.rejected, (state, action) => {
        console.error(action.error);
        state.predictions.error = action.error.message || 'Unknown error loading predictions';
        state.predictions.isLoading = false;
      })
      .addCase(fetchFieldConfig.pending, (state, _action) => {
        state.fieldConfig.isLoading = true;
      })
      .addCase(fetchFieldConfig.fulfilled, (state, action) => {
        const fieldConfig = action.payload;
        state.fieldConfig.isLoading = false;
        state.fieldConfig.config = { ...state.fieldConfig.config, ...fieldConfig };
      })
      .addCase(fetchFieldConfig.rejected, (state, action) => {
        console.error(action.error);
        state.fieldConfig.error = action.error.message || 'Unknown error loading field config';
        state.fieldConfig.isLoading = false;
      })
      .addCase(fetchDocuments.pending, (state, _action) => {
        state.documents.isLoading = true;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        const { nextToken } = action.payload;
        state.documents.isLoading = false;
        state.documents.nextToken = nextToken;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        console.error(action.error);
        state.documents.error = action.error.message || 'Unknown error loading documents';
        state.documents.isLoading = false;
      }),
});

export const actions = slice.actions;
export const reducer = slice.reducer;
