import { createStitches } from '@stitches/react';

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  theme: {
    colors: {
      primary: '#5f59f7',
      error50: '#FEF3F2',
      error300: '#FDA29B',
      error500: '#F04438',
      error700: '#B42318',
      error800: '#912018',
      gray25: '#FCFCFD',
      gray50: '#F9FAFB',
      gray100: '#F2F4F7',
      gray200: '#EAECF0',
      gray300: '#D0D5DD',
      gray400: '#98A2B3',
      gray500: '#667085',
      gray600: '#475467',
      gray700: '#344054',
      gray800: '#1D2939',
      gray900: '#101828',
      grayblue25: '#FCFCFD',
      grayblue50: '#F8F9FC',
      grayblue600: '#3E4784',
      grayblue700: '#363F72',
      graycool100: '#EFF1F5',
      graycool700: '#404968',
      grayneutral25: '#FCFCFD',
      indigo25: '#F5F8FF',
      indigo900: '#2D3282',
      rose400: '#FD6F8E',
      success50: '#ECFDF3',
      success100: '#D1FADF',
      success300: '#6CE9A6',
      success400: '#32D583',
      success500: '#12B76A',
      success700: '#027A48',
      teal100: '#CCFBEF',
      teal500: '#15B79E',
      teal900: '#134E48',
      primary50: '#F5F7FF',
      primary100: '#EBEBFF',
      primary200: '#E9D7FE',
      primary300: '#C4BBFB',
      primary500: '#5456F6',
      primary600: '#5A74EA',
      primary700: '#5456F6',
      warning50: '#FFFAEB',
      warning300: '#FEC84B',
      warning700: '#B54708',
    },
  },
  media: {
    bp1: '(min-width: 480px)',
  },
  utils: {
    mx: (value: any) => ({ marginLeft: value, marginRight: value }),
    my: (value: any) => ({ marginTop: value, marginBottom: value }),
  },
});
