import React, { ReactNode } from 'react';
import { styled } from '../../stitches.config';

const IconCardBase = styled('div', {
  backgroundColor: '$graycool100',
  borderRadius: '8px',
  width: '55px',
  height: '55px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$gray700',
});

export type IconCardProps = {
  children: ReactNode;
};

export function IconCard({ children }: IconCardProps): JSX.Element {
  return <IconCardBase>{children}</IconCardBase>;
}
