import { MantineThemeOverride } from '@mantine/core';

const customMantineTheme: MantineThemeOverride = {
  fontFamily: "'Cerebri Sans',sans-serif",
  components: {
    InputWrapper: {
      styles: (theme) => ({
        label: {
          fontFamily: theme.fontFamily,
        },
      }),
    },
  },
};

export default customMantineTheme;
