import { styled } from '../../stitches.config';

const StyledButton = styled('button', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: '8px',
  fontSize: '1rem',
  cursor: 'pointer',
  color: 'white',
  fontWeight: '500',
  lineHeight: 1,
  border: '1px solid transparent',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  '&:not(:disabled):active': {
    transform: 'translateY(1px)',
  },
  transition:
    'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  '&:focus-within': {
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
    outline: 'none',
  },
  defaultVariants: {
    variant: 'primary',
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: '$primary600',
        borderColor: '$primary600',
      },
      secondary: {
        backgroundColor: '$primary50',
        color: '$primary',
        borderColor: '$primary50',

        '&:hover': {
          backgroundColor: '$primary100',
          borderColor: '$primary100',
        },
      },
      success: {
        backgroundColor: '$success700',
      },
      'secondary-gray': {
        borderColor: '$gray300',
        backgroundColor: 'white',
        color: '$gray700',

        '&:hover': {
          color: '$gray800',
          backgroundColor: '$gray50',
          borderColor: '$gray300',
        },
      },
      'secondary-transparent': {
        borderColor: '$gray700',
        backgroundColor: 'transparent',
        color: '$grayblue25',

        '&:hover': {
          borderColor: '$gray800',
        },
      },
      'secondary-destructive': {
        borderColor: '$error300',
        backgroundColor: 'white',
        color: '$error700',

        '&:hover': {
          color: '$error800',
          backgroundColor: '$error50',
          borderColor: '$error300',
        },
      },
      'soft-success': {
        color: '$success50',
        backgroundColor: '$success400',
      },
      danger: {
        backgroundColor: '$error500',
      },
      warn: {
        backgroundColor: '$warning300',
      },
    },
  },
});

export const Button = StyledButton;
