import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ListWorkflowExecutionsOptions, Workflow, WorkflowExecution } from '@lucidtech/las-sdk-core';
// import { defaultClient } from '../../__mocks__/@lucidtech/las-sdk-core'; // eslint-disable-line
import { defaultClient } from '../../auth';

export const MAX_BATCH_LOAD_PROCESSES = 200;

export type WorkflowExecutionWithInfo = WorkflowExecution & {
  completedBy?: Array<string>;
  stringifiedInput?: string;
  stringifiedOutput?: string;
  isLoading?: boolean;
};

interface WorkflowGroupState {
  nextToken: string | undefined | null;
  executions: Array<WorkflowExecutionWithInfo>;
  isLoading: boolean;
  error: string | null;
}

type WorkflowState = Workflow & {
  incoming: WorkflowGroupState;
  processed: WorkflowGroupState;
  errorStatus: WorkflowGroupState;
};

type WorkflowsState = {
  isLoading: boolean;
  workflows: Record<string, WorkflowState>;
};

export const deleteWorkflowExecution = createAsyncThunk(
  'workflows/deleteWorkflowExecution',
  async ({
    workflowId,
    group = 'incoming',
    workflowExecutionId,
  }: {
    workflowId: string;
    group?: string;
    workflowExecutionId: string;
  }) => {
    const client = defaultClient();
    await client.deleteWorkflowExecution(workflowId, workflowExecutionId);
    return [workflowId, group, workflowExecutionId];
  }
);

export const retryWorkflowExecution = createAsyncThunk(
  'workflows/retryWorkflowExecution',
  async ({
    workflowId,
    workflowExecutionId,
    nextTransitionId,
  }: {
    workflowId: string;
    workflowExecutionId: string;
    nextTransitionId: string;
  }) => {
    const client = defaultClient();
    await client.updateWorkflowExecution(workflowId, workflowExecutionId, { nextTransitionId });
    return { workflowId, workflowExecutionId };
  }
);

export const fetchWorkflows = createAsyncThunk('workflows/fetchWorkflows', async (_args, { dispatch }) => {
  const client = defaultClient();
  const { workflows } = await client.listWorkflows();
  return workflows;
});

const statusToWorkflowExecutionMap = {
  incoming: 'running',
  processed: ['succeeded', 'failed', 'rejected'],
  errorStatus: 'error',
};

type WorkflowExecutionsFilterOptions = keyof typeof statusToWorkflowExecutionMap;

export const fetchWorkflowExecutions = createAsyncThunk(
  'workflows/fetchWorkflowExecutions',
  async (
    { workflowId, group }: { workflowId: string; group: WorkflowExecutionsFilterOptions },
    { signal, dispatch, getState }
  ) => {
    let done = false;
    let requests = 0;
    let executions = 0;
    const state = getState() as any;
    let nextToken = state.workflows.workflows[workflowId][group].nextToken;

    const sortQueries: ListWorkflowExecutionsOptions = {
      sortBy: group === 'processed' ? 'endTime' : 'startTime',
      order: 'descending',
    };

    while (!done) {
      if (signal.aborted) {
        throw new Error('Canceling');
      }

      // no more results
      if (requests > 0 && !nextToken) {
        done = true;
        break;
      }

      if (executions >= MAX_BATCH_LOAD_PROCESSES) {
        done = true;
        break;
      }

      const client = defaultClient();
      const res = await client.listWorkflowExecutions(workflowId, {
        status: statusToWorkflowExecutionMap[group],
        maxResults: 100,
        nextToken,
        ...sortQueries,
      });

      nextToken = res.nextToken;

      dispatch(slice.actions.addWorkflowExecutions({ workflowId, group, executions: res.executions }));

      executions += res.executions.length;
      requests++;
    }

    return { nextToken, workflowId, group };
  }
);

export const fetchWorkflowExecutionsWithErrorStatus = createAsyncThunk(
  'workflows/fetchWorkflowExecutionsWithErrorStatus',
  async ({ workflowId }: { workflowId: string }, { signal, dispatch, getState }) => {
    let done = false;
    let requests = 0;
    let executions = 0;
    const state = getState() as any;
    let nextToken = state.workflows.workflows[workflowId]['errorStatus'].nextToken;

    const sortQueries: ListWorkflowExecutionsOptions = {
      sortBy: 'startTime',
      order: 'descending',
    };

    while (!done) {
      if (signal.aborted) {
        throw new Error('Canceling');
      }

      // no more results
      if (requests > 0 && !nextToken) {
        done = true;
        break;
      }

      if (executions >= MAX_BATCH_LOAD_PROCESSES) {
        done = true;
        break;
      }

      const client = defaultClient();
      const res = await client.listWorkflowExecutions(workflowId, {
        status: 'error',
        maxResults: 100,
        nextToken,
        ...sortQueries,
      });

      nextToken = res.nextToken;

      dispatch(slice.actions.addWorkflowExecutions({ workflowId, group: 'errorStatus', executions: res.executions }));

      executions += res.executions.length;
      requests++;
    }

    return { nextToken, workflowId };
  }
);

const slice = createSlice({
  name: 'workflows',
  initialState: { isLoading: true, workflows: {} } as WorkflowsState,
  reducers: {
    addWorkflowExecutions: (
      state,
      action: PayloadAction<{
        workflowId: string;
        group: WorkflowExecutionsFilterOptions;
        executions: Array<WorkflowExecutionWithInfo>;
      }>
    ): void => {
      const { workflowId, group, executions } = action.payload;
      // sanity check that this workflow exist first
      if (!state.workflows[workflowId]) {
        return;
      }
      // filter out new workflowexecutions that already exist, so we don't accidentally add duplicates
      const newWorkflowExecutionsToAdd = executions.filter(
        (newWorkflowExecution) =>
          !state.workflows[workflowId][group].executions.some(
            (workflowExecution: WorkflowExecutionWithInfo) =>
              workflowExecution.executionId === newWorkflowExecution.executionId
          )
      );
      const withStringifiedInputAndOutput = newWorkflowExecutionsToAdd.map((execution) => ({
        ...execution,
        stringifiedOutput: JSON.stringify(execution.output),
        stringifiedInput: JSON.stringify(execution.input),
      }));
      state.workflows[workflowId][group].executions =
        state.workflows[workflowId][group].executions.concat(withStringifiedInputAndOutput);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(deleteWorkflowExecution.fulfilled, (state, action) => {
        const [workflowId, group, workflowExecutionId] = action.payload;
        state.workflows[workflowId][group].executions = state.workflows[workflowId][group].executions.filter(
          (execution: WorkflowExecutionWithInfo) => execution.executionId !== workflowExecutionId
        );
      })
      .addCase(fetchWorkflows.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWorkflows.fulfilled, (state, action) => {
        const workflows = action.payload;
        for (const workflow of workflows) {
          const workflowState: WorkflowState = {
            ...workflow,
            incoming: {
              nextToken: undefined,
              executions: [],
              isLoading: true,
              error: null,
            },
            processed: {
              nextToken: undefined,
              executions: [],
              isLoading: true,
              error: null,
            },
            errorStatus: {
              nextToken: undefined,
              executions: [],
              isLoading: false,
              error: null,
            },
          };

          state.workflows[workflow.workflowId] = workflowState;
        }

        state.isLoading = false;
      })
      .addCase(fetchWorkflowExecutions.pending, (state, action) => {
        const { workflowId, group } = action.meta.arg;
        state.workflows[workflowId][group].isLoading = true;
      })
      .addCase(fetchWorkflowExecutions.fulfilled, (state, action) => {
        const { workflowId, group, nextToken } = action.payload;

        state.workflows[workflowId][group].nextToken = nextToken;
        state.workflows[workflowId][group].isLoading = false;
      })
      .addCase(fetchWorkflowExecutions.rejected, (state, action) => {
        const { workflowId, group } = action.meta.arg;
        console.error(action.error);
        state.workflows[workflowId][group].error = 'Error loading workflow executions';
        state.workflows[workflowId][group].isLoading = false;
      })
      .addCase(fetchWorkflowExecutionsWithErrorStatus.pending, (state, action) => {
        const { workflowId } = action.meta.arg;
        state.workflows[workflowId].errorStatus.isLoading = true;
      })
      .addCase(fetchWorkflowExecutionsWithErrorStatus.fulfilled, (state, action) => {
        const { workflowId, nextToken } = action.payload;

        state.workflows[workflowId].errorStatus.nextToken = nextToken;
        state.workflows[workflowId].errorStatus.isLoading = false;
      })
      .addCase(fetchWorkflowExecutionsWithErrorStatus.rejected, (state, action) => {
        const { workflowId } = action.meta.arg;
        console.error(action.error);
        state.workflows[workflowId].errorStatus.error = 'Error loading workflow executions';
        state.workflows[workflowId].errorStatus.isLoading = false;
      })
      .addCase(retryWorkflowExecution.pending, (state, action) => {
        const { workflowId, workflowExecutionId } = action.meta.arg;
        // find the workflow execution that is being retried
        const execution = state.workflows[workflowId].errorStatus.executions.find(
          (execution) => execution.executionId === workflowExecutionId
        );
        if (execution) {
          execution.isLoading = true;
        }
      })
      .addCase(retryWorkflowExecution.rejected, (state, action) => {
        const { workflowId, workflowExecutionId } = action.meta.arg;
        // find the workflow execution that is being retried
        const execution = state.workflows[workflowId].errorStatus.executions.find(
          (execution) => execution.executionId === workflowExecutionId
        );
        if (execution) {
          execution.isLoading = false;
        }
      })
      .addCase(retryWorkflowExecution.fulfilled, (state, action) => {
        const { workflowId, workflowExecutionId } = action.meta.arg;
        // find the workflow execution that is being retried, and remove it from processed
        const executionIndex = state.workflows[workflowId].errorStatus.executions.findIndex(
          (execution) => execution.executionId === workflowExecutionId
        );
        if (executionIndex >= 0) {
          state.workflows[workflowId].errorStatus.executions.splice(executionIndex, 1);
        }
      }),
});

export const actions = slice.actions;
export const reducer = slice.reducer;
