import React, { useEffect, useState } from 'react';
import { Container, Nav, NavItem, Modal, Badge } from 'reactstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ChevronsLeft, ChevronsRight, Heart } from 'react-feather';

import { RootState } from '../store';
import FAQ from './FAQ';
import { useSelector } from 'react-redux';
import { useCredentials } from '../auth';
import Version from './Version';
import { ReactComponent as SuperviseLogo } from '../assets/supervise-logo.svg';

import styles from './Sidebar.module.css';

interface SidebarProps {
  collapsed?: boolean;
  toggleCollapse: () => void;
}

const Sidebar = ({ collapsed = false, toggleCollapse }: SidebarProps): JSX.Element => {
  const workflows = useSelector((state: RootState) => state.workflows.workflows);
  const transitions = useSelector((state: RootState) => state.approve.transitions);
  const [showSupport, setShowSupport] = useState(false);
  const { pathname } = useLocation();
  const [incomingOpen, setIncomingOpen] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [processedOpen, setProcessedOpen] = useState(false);
  const [toggleHasBeenAutomaticallySet, setToggleHasBeenAutomaticallySet] = useState(false);

  useEffect(() => {
    if (!toggleHasBeenAutomaticallySet) {
      const isIncoming = pathname.includes('/incoming');
      const isApprove = pathname.includes('/approve');
      const isProcessed = pathname.includes('/processed');
      if (isIncoming) {
        setIncomingOpen(true);
        setToggleHasBeenAutomaticallySet(true);
      } else if (isApprove) {
        setApproveOpen(true);
        setToggleHasBeenAutomaticallySet(true);
      } else if (isProcessed) {
        setProcessedOpen(true);
        setToggleHasBeenAutomaticallySet(true);
      }
    }
  }, [pathname, toggleHasBeenAutomaticallySet]);

  const credentials = useCredentials();
  const logout = (): void => {
    window.localStorage.removeItem('AuthToken');
    credentials.initiateLogoutFlow();
  };

  const toggleSupport = (): void => setShowSupport(!showSupport);

  return (
    <nav
      className={`navbar fixed-left navbar-vertical navbar-expand-md navbar-dark navbar-vibrant ${styles.sidebar} ${
        collapsed ? '' : styles.isOpen
      }`}
      id="sidebar"
    >
      <Container fluid className={collapsed ? 'justify-content-end' : ''}>
        {!collapsed && (
          <>
            <a href="/">
              <SuperviseLogo className={styles.logo} />
            </a>

            <div className="navbar-collapse collapse show" id="sidebarCollapse">
              <ul className="navbar-nav mt-5">
                <NavItem>
                  <NavLink activeClassName="active" className="nav-link" to="/dashboard">
                    <i className="fe fe-activity" />
                    Dashboard
                  </NavLink>
                </NavItem>

                <NavItem id="incomingToggler">
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    data-toggle="collapse"
                    aria-expanded={incomingOpen}
                    data-target="#incomingSubmenu"
                    aria-controls="incomingSubmenu"
                    to="/incoming"
                  >
                    <i className="fe fe-home" />
                    Incoming
                  </NavLink>
                </NavItem>
                <div className={incomingOpen ? 'collapse in show' : 'collapse'} id="incomingSubmenu">
                  <Nav vertical className="nav-sm">
                    {Object.values(workflows).map((workflow) => (
                      <NavItem key={workflow.workflowId}>
                        <NavLink activeClassName="active" className="nav-link" to={`/incoming/${workflow.workflowId}`}>
                          {workflow.name}
                          <Badge color="secondary" className="ml-auto">
                            {workflow.numberOfRunningExecutions}
                          </Badge>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>

                <NavItem id="approveToggler">
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    data-toggle="collapse"
                    aria-expanded={approveOpen}
                    data-target="#approveSubmenu"
                    aria-controls="approveSubmenu"
                    to="/approve"
                  >
                    <i className="fe fe-eye" />
                    Approve
                  </NavLink>
                </NavItem>

                <div className={approveOpen ? 'collapse in show' : 'collapse'} id="approveSubmenu">
                  <Nav vertical className="nav-sm">
                    {Object.entries(transitions)
                      .filter(([, transition]) => transition.transition.transitionType === 'manual')
                      .map(([transitionId, transition]) => (
                        <NavItem key={transitionId}>
                          <NavLink activeClassName="active" className="nav-link" to={`/approve/${transitionId}`}>
                            {transition.transition.name}
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                </div>

                <NavItem id="processedToggler">
                  <NavLink
                    activeClassName="active"
                    className="nav-link"
                    data-toggle="collapse"
                    aria-expanded={processedOpen}
                    data-target="#processedSubmenu"
                    aria-controls="processedSubmenu"
                    to="/processed"
                  >
                    <i className="fe fe-check-circle" />
                    Processed
                  </NavLink>
                </NavItem>
                <div className={processedOpen ? 'collapse in show' : 'collapse'} id="processedSubmenu">
                  <Nav vertical className="nav-sm">
                    {Object.values(workflows).map((workflow) => (
                      <NavItem key={workflow.workflowId}>
                        <NavLink activeClassName="active" className="nav-link" to={`/processed/${workflow.workflowId}`}>
                          {workflow.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
              </ul>

              <hr className="navbar-divider my-3" />

              <h6 className="navbar-heading">Administration</h6>

              <ul className="navbar-nav">
                <NavItem>
                  <NavLink activeClassName="active" className="nav-link" to="/admin">
                    <i className="fe fe-settings" />
                    Settings
                  </NavLink>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="#" onClick={toggleSupport}>
                    <i className="fe fe-help-circle" />
                    Support
                  </Link>

                  <Modal isOpen={showSupport} toggle={toggleSupport}>
                    <div className="modal-card card">
                      <div className="card-header">
                        <h4 className="card-header-title">Frequently Asked Questions</h4>
                      </div>
                      <div className="card-body">
                        <FAQ />
                      </div>
                    </div>
                  </Modal>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="#" onClick={logout}>
                    <i className="fe fe-log-out" />
                    Sign out
                  </Link>
                </NavItem>
              </ul>
            </div>
            <div className="text-light text-center">
              <p className="mb-1">
                Made with <Heart fill="#e63757" stroke="#e63757" size={16} /> in Norway
              </p>
              <Version />
            </div>
          </>
        )}
        <div className="align-self-center">
          <button onClick={toggleCollapse} className={styles['sidebar-toggle']} title="Ctrl + shift + Z to toggle">
            {collapsed ? <ChevronsRight size={14} /> : <ChevronsLeft size={14} />}
          </button>
        </div>
      </Container>
    </nav>
  );
};

export default Sidebar;
