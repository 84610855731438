import React from 'react';
import { WorkflowExecution } from '@lucidtech/las-sdk-core';
import { ReactComponent as SourceEmailIcon } from './icons/source_email.svg';
import { ReactComponent as SourceDefaultIcon } from './icons/source_default.svg';
import { ReactComponent as SourceScannerIcon } from './icons/source_scanner.svg';
import { defaultClient } from './auth';

const BASE64_MARKER = ';base64,';

export function convertDataURIToBinary(dataURI: string): Uint8Array {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export const getSourceIcon = (item: WorkflowExecution): JSX.Element => {
  const getIcon = (Icon: any): JSX.Element => <Icon style={{ transform: 'scale(0.9)' }} />;

  const { documentSource } = item.input;
  switch (documentSource) {
    case 'SCANNER':
      return getIcon(SourceScannerIcon);
    case 'EMAIL':
      return getIcon(SourceDefaultIcon);
    default:
      return getIcon(SourceEmailIcon);
  }
};

/**
 * Decode base64 encoded content to unicode string
 * @param str
 */
export function b64DecodeUnicode(str: string): string {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

/**
 * Convenience function to add a hidden anchor tag to use for download initiation
 * @param uri
 * @param filename
 */
export const initiateDownload = (uri: string, filename: string): void => {
  const a = document.createElement('a');
  a.setAttribute('href', uri);
  a.setAttribute('style', 'display: none;');
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(uri);
  a.remove();
};

export const b64toBlob = async (base64: string, type = 'application/octet-stream'): Promise<Blob> => {
  const res = await fetch(`data:${type};base64,${base64}`);
  const blob = await res.blob();
  return blob;
};

export const downloadDocument = async (documentId: string): Promise<void> => {
  const client = defaultClient();
  const doc = await client.getDocument(documentId);
  const blob = await b64toBlob(doc.content, doc.contentType);
  const uri = URL.createObjectURL(blob);
  initiateDownload(uri, documentId);
};
