import { configureStore, combineReducers, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { reducer as sessionReducer } from './slices/session';
import { reducer as workflowsReducer } from './slices/workflows';
import { reducer as approveReducer } from './slices/approve';
import { reducer as dashboardReducer } from './slices/dashboard';
import { reducer as usersReducer } from './slices/users';

const rootReducer = combineReducers({
  session: sessionReducer,
  workflows: workflowsReducer,
  approve: approveReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const extraMiddleware: any = [];
// be aware: make sure redux-logger is last in the middleware list
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true, level: 'debug' });
  extraMiddleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  // instead of creating the default middleware, we do this because these "immutable state blabla variation took x ms" console.warning
  // calls are super annoying during development and aren't helpful at all.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(extraMiddleware),
});

export type AppDispatch = ThunkDispatch<unknown, unknown, AnyAction>;

export default store;
