/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as las from '@lucidtech/las-sdk-browser';
import { Token, TokenStorage } from '@lucidtech/las-sdk-core';
import { Client } from '@lucidtech/las-sdk-core';

export class LocalStorage implements TokenStorage<Token> {
  private keyName: string;

  constructor(keyName = 'AuthToken') {
    this.keyName = keyName;
  }

  getPersistentToken(): Token | null {
    const tokenString = window.localStorage.getItem(this.keyName);

    if (!tokenString) {
      return null;
    }

    const { accessToken, expiration, refreshToken } = JSON.parse(tokenString);

    if (!(accessToken && expiration && refreshToken)) {
      return null;
    }

    return new Token(accessToken, expiration, refreshToken);
  }

  setPersistentToken(t: Token): void {
    let token = t;

    const oldToken = window.localStorage.getItem(this.keyName);
    if (oldToken) {
      const refreshToken = JSON.parse(oldToken).refreshToken;
      token = new Token(t.accessToken, t.expiration, refreshToken);
    }

    const tokenString = JSON.stringify(token);
    window.localStorage.setItem(this.keyName, tokenString);
  }
}

export function createCredentials(clientId?: string | null, code?: las.PKCEDerived): las.AuthorizationCodeCredentials {
  const storage = new LocalStorage();
  const launchUri = (uri: string): void => {
    window.open(uri, '_self');
  };

  const clientIdFromLocalStorage = window.localStorage.getItem('clientId');
  const computedClientId = clientId || clientIdFromLocalStorage || '';

  return new las.AuthorizationCodeCredentials(
    process.env.REACT_APP_API_ENDPOINT!,
    computedClientId,
    process.env.REACT_APP_AUTH_ENDPOINT!,
    process.env.REACT_APP_REDIRECT_URI!,
    launchUri,
    code,
    storage,
    process.env.REACT_APP_LOGOUT_REDIRECT_URI!
  );
}

export const hasCredentials = (): boolean => {
  if (window.localStorage.getItem('AuthToken')) {
    return true;
  } else {
    return false;
  }
};

export const useCredentials = (clientId?: string, pkce?: las.PKCEDerived): las.AuthorizationCodeCredentials =>
  createCredentials(clientId, pkce);

export const defaultClient = (clientId?: string, pkce?: las.PKCEDerived): Client => {
  return new Client(createCredentials(clientId, pkce));
};
